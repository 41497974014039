<template>
  <div id="feeDaily" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Fee Daily</div>
      </div>
    </div>
    <div id="selectMonthOwner" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Select Data From</div>
      </div>
      <div class="box-S2">
        <div class="B-carddetail flex-between">
          <!-- <div class="box-S2 noPadding">
            <v-select
              :items="itemsListCompany"
              v-model="selectcompany"
              item-text="c_name"
              item-value="c_id"
              label="Owner"
              return-object
              dense
              hide-details
              @change="RenderFlight()"
            ></v-select>
          </div> -->
          <!-- Edit -->
          <div class="box-S2 noPadding">
            <v-select
              :items="itemsListCustomer"
              v-model="selectCustomer"
              item-text="o_name"
              item-value="o_id"
              label="Customer"
              return-object
              dense
              hide-details
              @change="RenderFlight()"
            ></v-select>
          </div>
          <div class="box-S2 noPadding">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="0"
              :nudge-top="0"
              transition="scale-transition"
              offset-y
              hide-details
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="hideMessage input-date"
                  append-icon="mdi-calendar-range"
                  v-model="picker"
                  label="Select Month"
                  readonly
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="picker"
                type="date"
                @input="menu = false"
                @change="RenderFlight()"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>

    <div id="ReportData" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Report</div>
      </div>
      <div class="B-ReportData box-S4">
        <div
          class="B-carddetail b-flightData"
          v-for="(item, index) in arrFlight"
          :key="item"
        >
          <div class="b-row">
            <div class="N-Page T-size-20">
              Flight {{ index + 1 }} ({{ item.ap_depart_date | HHmm }})
            </div>
          </div>
          <div class="b-row flex-between-center">
            <div class="box-S2">
              <v-text-field
                label="Flight No."
                hide-details
                disabled
                v-model="item.f_flight_no"
              ></v-text-field>
            </div>
            <div class="box-S2">
              <v-text-field
                label="Helicopter"
                hide-details
                disabled
                value="HS-UOB"
                v-model="item.ac_name"
              ></v-text-field>
            </div>
          </div>
          <div class="b-row flex-between-center">
            <div class="box-S2">
              <v-text-field
                label="Flight Type"
                hide-details
                disabled
                v-model="item.f_type_of_flight"
              ></v-text-field>
            </div>
            <div class="box-S2">
              <v-btn
                class="theme-btn-even"
                :to="{
                  name: 'FlightLogDetail',
                  params: { mode: 'Edit', id: item.f_id },
                }"
                target="_blank"
              >
                Go to Flight Report
              </v-btn>
            </div>
          </div>
          <div class="line"></div>

          <div class="b-row flex-between-center">
            <div class="box-S2">
              <v-text-field
                label="Flying hour charge"
                hide-details
                v-model="item.flying_hour_charge"
                disabled
              ></v-text-field>
            </div>
            <div class="box-S2">
              <v-text-field
                v-if="customer.c_sap_fuel == 1"
                label="Fuel charge"
                hide-details
                v-model="item.fuel_charge"
                disabled
              ></v-text-field>
            </div>
          </div>
          <div class="b-row flex-between-center">
            <div class="box-S2">
              <v-text-field
                v-if="customer.c_sap_anf == 1"
                label="Air Navigation Fee"
                hide-details
                v-model="item.air_nivigation_fee"
                disabled
              ></v-text-field>
            </div>
            <div class="box-S2">
              <v-text-field
                v-if="customer.c_sap_ghs == 1"
                label="Ground handling service"
                hide-details
                v-model="item.ground_handing_service"
                disabled
              ></v-text-field>
            </div>
          </div>

          <div class="line"></div>

          <div class="b-row flex-between-center">
            <div class="box-S2">
              <v-text-field
                label="Delay Cause"
                hide-details
                v-model="item.d_name"
                disabled
              ></v-text-field>
            </div>
            <div class="box-S2">
              <v-text-field
                label="Delay Code"
                hide-details
                v-model="item.dc_code_name"
                disabled
              ></v-text-field>
            </div>
          </div>

          <div class="b-row flex-between-center">
            <div class="box-approve" v-if="item.f_base_approve">
              <span class="title">Approved by</span>
              <span class="by">
                {{ item.f_base_approve_by }}
                {{ item.f_base_approve_time | yyyyMMddHHmm }}</span
              >
            </div>
          </div>

          <div class="line marginTop"></div>

          <div class="b-row">
            <div class="N-Page T-size-20">Financial Data</div>
          </div>

          <div class="b-row flex-between-center">
            <div class="box-S1-3 noPadding">
              <v-select
                :items="itemsListProject"
                item-text="sp_name"
                item-value="sp_code"
                label="Project"
                dense
                hide-details
                v-model="item.project"
              ></v-select>
            </div>
            <div class="box-S1-3 noPadding">
              <v-select
                :items="itemsListDepartment"
                item-text="scd_name"
                item-value="scd_code"
                label="Department"
                dense
                hide-details
                v-model="item.department"
              ></v-select>
            </div>
            <div class="box-S1-3 noPadding">
              <v-select
                :items="itemsListBOI"
                item-text="scb_name"
                item-value="scb_code"
                label="BOI"
                dense
                hide-details
                v-model="item.boi"
              ></v-select>
            </div>
          </div>

          <div class="b-row">
            <div class="box-S4">
              <v-text-field
                label="ค่าบินช้า (Minute)"
                hide-details
                v-model="item.flying_late"
              ></v-text-field>
            </div>
            <div class="box-approve" v-if="item.f_finance_approve">
              <span class="title">Send to SAP</span>
              <span class="by">
                {{ item.f_finance_approve_by }}
                {{ item.f_finance_approve_time | yyyyMMddHHmm }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>

    <div id="partSave" class="wrap-Main">
      <div class="box-S4">
        <div class="b-btnSend">
          <v-btn
            class="theme-btn-even btn-sendSap Bsize150"
            @click="dialogSentToSAP = true"
          >
            <span class="T-size-18">Send to SAP</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <!-- Add-->
    <v-dialog v-model="dialogSentToSAP" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Send data to SAP</div>
            <div class="t-des">Are you sure to send data to SAP?</div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-cancel Bsize100"
              text
              @click="dialogSentToSAP = false"
            >
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn
              class="theme-btn-even btn-save Bsize100"
              text
              @click="SaveList()"
            >
              <span class="T-size-18">Yes</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add -->
    <v-dialog v-model="dialogConfirmSentToSAP" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Sent To SAP Completed</div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-ok Bsize100"
              text
              @click="GotoComplete()"
            >
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import feathersClientSAP from "../plugins/feathers-client-sap";
import { startOfDay, endOfDay, format } from "date-fns";
import fillerdate from "../utils/filterdate";

export default {
  data: () => ({
    selectcompany: "",
    itemsListCompany: [],
    //Edit
    selectCustomer: "",
    itemsListCustomer: [],

    itemsListProject: [],
    itemsListDepartment: [],
    arrFlight: [],
    F_Minute: "",
    quantityFl: [],
    allselect: [],
    itemsListStatus: [
      { code: "Rev-003", itemname: "Revenue - Fuel charge" },
      { code: "Rev-004", itemname: "Revenue - Air Navigation Fee" },
      { code: "Rev-005", itemname: "Revenue - Ground handling service" },
    ],
    itemsListBOI: [],
    menu: false,
    picker: new Date().toISOString().substr(0, 10),

    dialogSentToSAP: false,
    dialogConfirmSentToSAP: false,

    customer: {},
  }),
  async mounted() {
    this.userdata = JSON.parse(localStorage.getItem("user"));
    //await this.SelectListCompany();
    //Edit
    await this.SelectListCustomer();
    await this.SelectListProject();
    await this.SelectListDepartment();
    await this.SelectListBOI();

    //await this.RenderFlight();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async RenderFlight() {
      try {
        //Get Customer
        let queryCustomer = {};
        queryCustomer.o_id = this.selectCustomer.o_id;
        this.customer = {};
        let resCustomer = await feathersClientUOA
          .service("customer")
          .find({ query: queryCustomer });
        this.customer = resCustomer.data[0];
        // alert(JSON.stringify(this.customer))

        var query = {};
        query.o_name = this.selectCustomer.o_name;
        query.ap_depart_date = {
          $gte: this.picker + " 00:00:00",
          $lte: this.picker + " 23:59:59",
        };

        var res = await feathersClientUOA
          .service("viewflightfinance")
          .find({ query: query });

        //alert(JSON.stringify(res))
        this.arrFlight = [];

        res.data.forEach((element) => {
          var flying_hour_charge = "";

          //if( this.selectcompany.c_name == 'CPOC' || this.selectcompany.c_name == 'PTTEPI' ){
          //Edit
          if (
            this.selectCustomer.o_id == 2 ||
            this.selectCustomer.o_id == 6
          ) {
            flying_hour_charge = element.airtime;
          } else {
            flying_hour_charge = element.blocktime;
          }
          var arritem = {};
          arritem["f_flight_no"] = element.f_flight_no;
          arritem["ac_name"] = element.ac_name;
          arritem["project"] = "";
          arritem["department"] = "";
          arritem["boi"] = "";
          arritem["flying_hour_charge"] = flying_hour_charge;
          // arritem["fuel_charge"] = this.selectcompany.c_sap_fuel === '1' ? element.sum_fual : 0;
          // arritem["air_nivigation_fee"] = this.selectcompany.c_sap_anf === '1' ? element.air_fee : 0;
          // arritem["ground_handing_service"] = this.selectcompany.c_sap_ghs === '1' ? '1' : 0;
          //Edit
          arritem["fuel_charge"] =
            this.selectCustomer.c_sap_fuel === "1" ? element.sum_fuel : 0;
          arritem["air_nivigation_fee"] =
            this.selectCustomer.c_sap_anf === "1" ? element.air_fee : 0;
          arritem["ground_handing_service"] =
            this.selectCustomer.c_sap_ghs === "1" ? "1" : 0;
          arritem["flying_late"] = null;

          //Add
          arritem["f_id"] = element.f_id;
          arritem["ap_depart_date"] = element.ap_depart_date;
          arritem["f_type_of_flight"] = element.f_type_of_flight;
          arritem["d_name"] = element.d_name;
          arritem["dc_code_name"] = element.dc_code_name;

          arritem["f_base_approve"] = element.f_base_approve;
          arritem["f_base_approve_by"] = element.f_base_approve_by;
          arritem["f_base_approve_time"] = element.f_base_approve_time;

          arritem["f_finance_approve"] = element.f_finance_approve;
          arritem["f_finance_approve_by"] = element.f_finance_approve_by;
          arritem["f_finance_approve_time"] = element.f_finance_approve_time;

          this.arrFlight.push(arritem);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListCompany() {
      try {
        var q = {};
        // q.c_code_ref = 1;
        //Edit
        q.c_code_ref = "1";
        var res = await feathersClientUOA.service("company").find({ query: q });
        this.itemsListCompany = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListCustomer() {
      try {
        var q = {};
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListCustomer = res.data.filter(
          (x) => x.c_sap_customer_code != null
        );
        this.selectCustomer = {
          o_id: this.itemsListCustomer[0].o_id,
          o_name: this.itemsListCustomer[0].o_name,
        };
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListProject() {
      try {
        var res = await feathersClientUOA.service("sapproject").find({});

        this.itemsListProject = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListDepartment() {
      try {
        var res = await feathersClientUOA.service("sapcogsdepartment").find({});

        this.itemsListDepartment = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListBOI() {
      try {
        var res = await feathersClientUOA.service("sapcogsboi").find({});

        this.itemsListBOI = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SaveList() {
      for (let index = 0; index < this.arrFlight.length; index++) {
        if (this.arrFlight[index].fuel_charge != 0) {
          if (this.arrFlight[index].air_nivigation_fee != 0) {
            if (this.arrFlight[index].ground_handing_service != 0) {
              //Add
              var dataEdit = {};

              this.allselect = [];
              this.quantityFl = [];

              var selectData = {};
              selectData["boi"] = this.arrFlight[index].boi;
              selectData["project"] = this.arrFlight[index].project;
              selectData["department"] = this.arrFlight[index].department;

              this.allselect.push(selectData);

              //Add
              let key = Math.floor(Math.random() * 90000) + 10000;
              let key2 = Math.floor(Math.random() * 90000) + 10000;
              let key3 = Math.floor(Math.random() * 90000) + 10000;

              // await this.quantityFl.push(
              //   this.arrFlight[index].fuel_charge,
              //   this.arrFlight[index].air_nivigation_fee,
              //   this.arrFlight[index].ground_handing_service
              // );
              //Edit
              //Check Customer
              //data => [1283,0,"1"]
              if (this.customer.c_sap_fuel == 1) {
                await this.quantityFl.push(this.arrFlight[index].fuel_charge);
              }
              if (this.customer.c_sap_anf == 1) {
                await this.quantityFl.push(
                  this.arrFlight[index].air_nivigation_fee
                );
              }
              if (this.customer.c_sap_ghs == 1) {
                await this.quantityFl.push(
                  this.arrFlight[index].ground_handing_service
                );
              }

              await this.SaveHour(
                this.arrFlight[index].f_flight_no + "-" + key,
                this.arrFlight[index].flying_hour_charge,
                this.allselect
              );
              await this.SaveOther(
                this.arrFlight[index].f_flight_no + "-" + key2,
                this.quantityFl,
                this.allselect
              );

              if (this.arrFlight[index].flying_late != null) {
                await this.SaveDelay(
                  this.arrFlight[index].f_flight_no + "-" + key3,
                  this.arrFlight[index].flying_late,
                  this.allselect
                );
              }

              //Add Update to flight
              dataEdit = {
                f_finance_approve: true,
                f_finance_approve_by: this.userdata.FullName,
                f_finance_approve_time: format(
                  new Date(),
                  "yyyy-MMM-dd HH:mm:ss"
                ),
                mb: this.userdata.FullName,
                md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
              };

              var res = await feathersClientUOA
                .service("flight")
                .patch(this.arrFlight[index].f_id, dataEdit);
            } else {
              //let text = this.arrFlight[index].
              //alert("ground_handing_service : " + this.arrFlight[0].ground_handing_service)
            }
          } else {
            //alert("air_nivigation_fee : " + this.arrFlight[0].air_nivigation_fee)
          }
        } else {
          //alert("air_nivigation_fee : " + this.arrFlight[0].fuel_charge)
        }
      }

      this.dialogSentToSAP = false;
      this.dialogConfirmSentToSAP = true;
      this.RenderFlight();
    },
    async SaveHour(data, minute, allselect) {
      let DocDate = new Date()
        .toISOString()
        .split("T", 1)
        .toString()
        .replace("-", "")
        .replace("-", "");
      var DataInsertHead = {
        Series: "H",
        DocDate: DocDate,
        // CardCode:this.selectcompany.c_sap_customer_code,
        // CardName:this.selectcompany.c_sap_customer_name,
        CardCode: this.selectCustomer.c_sap_customer_code,
        CardName: this.selectCustomer.c_sap_customer_name,
        CardContact: null,
        Remark: null,
        FlightOperation: this.userdata.FullName,
        CreateDate: DocDate,
        SOInternalKey: data,
        INFStatus: 0,
      };
      //alert("SaveHour : " + JSON.stringify(DataInsertHead))
      this.InsertHeadHour(DataInsertHead, minute, allselect);
    },
    async SaveOther(data, quantity, allselect) {
      let DocDate = new Date()
        .toISOString()
        .split("T", 1)
        .toString()
        .replace("-", "")
        .replace("-", "");
      var DataInsertHead = {
        Series: "O",
        DocDate: DocDate,
        // CardCode:this.selectcompany.c_sap_customer_code,
        // CardName:this.selectcompany.c_sap_customer_name,
        CardCode: this.selectCustomer.c_sap_customer_code,
        CardName: this.selectCustomer.c_sap_customer_name,
        CardContact: null,
        Remark: null,
        FlightOperation: this.userdata.FullName,
        CreateDate: DocDate,
        SOInternalKey: data,
        INFStatus: 0,
      };
      //alert("SaveOther : " + JSON.stringify(DataInsertHead))
      this.InsertHeadOthor(DataInsertHead, quantity, allselect);
    },
    async SaveDelay(data, flyinglate, allselect) {
      let DocDate = new Date()
        .toISOString()
        .split("T", 1)
        .toString()
        .replace("-", "")
        .replace("-", "");
      var DataInsertHead = {
        Series: "M",
        DocDate: DocDate,
        // CardCode:this.selectcompany.c_sap_customer_code,
        // CardName:this.selectcompany.c_sap_customer_name,
        CardCode: this.selectCustomer.c_sap_customer_code,
        CardName: this.selectCustomer.c_sap_customer_name,
        CardContact: null,
        Remark: null,
        FlightOperation: this.userdata.FullName,
        CreateDate: DocDate,
        SOInternalKey: data,
        INFStatus: 0,
      };
      //alert(JSON.stringify(DataInsertHead))
      this.InsertHeadDelay(DataInsertHead, flyinglate, allselect);
    },
    async InsertHeadHour(DataInsertHead, minute, allselect) {
      try {
        var res = await feathersClientUOA
          .service("sapinfsohead")
          .create(DataInsertHead);

        this.GenLineHour(res.soh_id, minute, allselect);
        //this.GenLine(13);
      } catch (error) {
        console.log(error);
      }

      try {
        var res = await feathersClientSAP
          .service("infsohead")
          .create(DataInsertHead);

        this.GenLineHourSAP(res.ID, minute, allselect);
        //this.GenLine(13);
      } catch (error) {
        console.log(error);
      }
    },
    async InsertHeadOthor(DataInsertHead, quantity, allselect) {
      try {
        var res = await feathersClientUOA
          .service("sapinfsohead")
          .create(DataInsertHead);

        this.GenLineOther(res.soh_id, quantity, allselect);
        //this.GenLine(13);
      } catch (error) {
        console.log(error);
      }

      try {
        var res = await feathersClientSAP
          .service("infsohead")
          .create(DataInsertHead);

        this.GenLineOtherSAP(res.ID, quantity, allselect);
        //this.GenLine(13);
      } catch (error) {
        console.log(error);
      }
    },
    async InsertHeadDelay(DataInsertHead, flyinglate, allselect) {
      try {
        var res = await feathersClientUOA
          .service("sapinfsohead")
          .create(DataInsertHead);

        this.GenLineDelay(res.soh_id, flyinglate, allselect);
        //this.GenLine(13);
      } catch (error) {
        console.log(error);
      }

      try {
        var res = await feathersClientSAP
          .service("infsohead")
          .create(DataInsertHead);

        this.GenLineDelaySAP(res.ID, flyinglate, allselect);
        //this.GenLine(13);
      } catch (error) {
        console.log(error);
      }
    },
    async GenLineHour(idHead, minute, allselect) {
      var DataInsertLine = {
        soh_id: idHead,
        LineNo: 1,
        Itemcode: "Rev-002",
        ItemName: "Revenue - Flying hour charge",
        Quantity: null,
        Project: allselect[0].project,
        Ocrcode: allselect[0].department,
        Ocrcode2: allselect[0].boi,
        Minute: minute,
      };

      this.InsertLineHour(DataInsertLine);
    },
    async GenLineHourSAP(idHead, minute, allselect) {
      var DataInsertLine = {
        HeadID: idHead,
        LineNo: 1,
        Itemcode: "Rev-002",
        ItemName: "Revenue - Flying hour charge",
        Quantity: null,
        Project: allselect[0].project,
        Ocrcode: allselect[0].department,
        Ocrcode2: allselect[0].boi,
        Minute: minute,
      };
      this.InsertLineHourSAP(DataInsertLine);
    },
    async GenLineOther(idHead, quantity, allselect) {
      for (let i = 0; i < this.itemsListStatus.length; i++) {
        var DataInsertLine = {
          soh_id: idHead,
          LineNo: i + 1,
          Itemcode: this.itemsListStatus[i].code,
          ItemName: this.itemsListStatus[i].itemname,
          Quantity: quantity[i],
          Project: allselect[0].project,
          Ocrcode: allselect[0].department,
          Ocrcode2: allselect[0].boi,
          Minute: 0,
        };

        this.InsertLineOther(DataInsertLine);
      }
    },
    async GenLineOtherSAP(idHead, quantity, allselect) {
      for (let i = 0; i < this.itemsListStatus.length; i++) {
        var DataInsertLine = {
          HeadID: idHead,
          LineNo: i + 1,
          Itemcode: this.itemsListStatus[i].code,
          ItemName: this.itemsListStatus[i].itemname,
          Quantity: quantity[i],
          Project: allselect[0].project,
          Ocrcode: allselect[0].department,
          Ocrcode2: allselect[0].boi,
          Minute: 0,
        };

        this.InsertLineOtherSAP(DataInsertLine);
      }
    },
    async GenLineDelay(idHead, flyinglate, allselect) {
      var DataInsertLine = {
        soh_id: idHead,
        LineNo: 1,
        Itemcode: "Dis0001",
        ItemName: "ค่าบินช้า",
        Quantity: null,
        Project: allselect[0].project,
        Ocrcode: allselect[0].department,
        Ocrcode2: allselect[0].boi,
        Minute: flyinglate,
      };
      this.InsertLineOther(DataInsertLine);
    },
    async GenLineDelaySAP(idHead, flyinglate, allselect) {
      var DataInsertLine = {
        HeadID: idHead,
        LineNo: 1,
        Itemcode: "Dis0001",
        ItemName: "ค่าบินช้า",
        Quantity: null,
        Project: allselect[0].project,
        Ocrcode: allselect[0].department,
        Ocrcode2: allselect[0].boi,
        Minute: flyinglate,
      };
      this.InsertLineOtherSAP(DataInsertLine);
    },
    async InsertLineHour(DataInsertLine) {
      try {
        var res = await feathersClientUOA
          .service("sapinfsoline")
          .create(DataInsertLine);
      } catch (error) {
        console.log(error);
      }
    },
    async InsertLineHourSAP(DataInsertLine) {
      try {
        var res = await feathersClientSAP
          .service("infsoline")
          .create(DataInsertLine);
      } catch (error) {
        console.log(error);
      }
    },
    async InsertLineOther(DataInsertLine) {
      try {
        var res = await feathersClientUOA
          .service("sapinfsoline")
          .create(DataInsertLine);
      } catch (error) {
        console.log(error);
      }
    },
    async InsertLineOtherSAP(DataInsertLine) {
      try {
        var res = await feathersClientSAP
          .service("infsoline")
          .create(DataInsertLine);
      } catch (error) {
        console.log(error);
      }
    },

    async GotoComplete() {
      this.dialogConfirmSentToSAP = false;
    },
  },
};
</script>
<style scoped >
.box-approve {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 0.8em !important;
}
.by {
  font-size: 0.8em !important;
}
.time {
  font-size: 0.8em !important;
}
</style>